import * as React from 'react'
import SEOTools from '../components/SEOTools'
import { Col, Container, Row } from 'react-bootstrap'
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'

/**
 * @returns {JSX.Element}
 * @constructor
 */
const NotFoundPage = () => {
    return (
        <NotFoundStyles>
            <SEOTools title='Page not found'>
                <meta name='robots' content='noindex' />
            </SEOTools>

            <section className='dark-background-overlay position-relative'>
                <div className='text-white mb-0 d-flex align-items-center full-screen-jumbo'>
                    <Container className='my-5 py-5'>
                        <Row className='d-flex justify-content-center text-center'>
                            <Col md='8'>
                                <h1>404</h1>
                                <p className='h2'>This is not the webpage you are looking for.</p>
                            </Col>
                        </Row>
                    </Container>
                    <StaticImage
                        className='z-index-n1 position-absolute w-100 h-100'
                        src='../images/sirumobile_404.jpg'
                        alt=''
                        role='presentation'
                        placeholder='blurred'
                        loading='eager'
                        layout='fullWidth'
                    />
                </div>
            </section>
        </NotFoundStyles>
    )
}

const NotFoundStyles = styled.div`
    h1 {
        font-family: 'Open Sans', sans-serif;
        font-size: 6rem;
    }
`

export default NotFoundPage
